
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import ImageCropper from '../ImageCropper.component.vue';
  import CustomImage from '../CustomImage.component.vue';

  const imgCroppedEvent = "cover-img-cropped";

  /**
   * @desc composant permettant la selection d'une image de couverture pour les articles
   */
  @Component({
    components: {
      'image-cropper': ImageCropper,
      'c-img' : CustomImage,
    },
  })
  export default class ArticleCoverImgSelector extends Vue {

    private _hasChanged = false;
    private dataUrl = "";
    private imgPreview : CustomImage | null = null;

    private imgSrc = "";

    @Prop({default: 0})
    initialId? : number;

    /**
     * @desc rechargement de l'image en cas de changement d'id externe
     */
    @Watch('initialId')
    initIdChanged() : void {
      if(this.initialId) {
        this.imgSrc = '';
        this.loadImg(this.initialId);
        this._hasChanged = false;
      }
      else {
        this.removeImg();
        this._hasChanged = false;
      }
    }

    /**
     * @desc défini la variable contenant le composant d'affichage de l'image
     */
    private setImgPreview() : void {
      if(!this.imgPreview)
        this.imgPreview = this.$refs.imgPreview as CustomImage;
    }

    /**
     * @desc applique un click sur le file input, ce qui permet de choisir
     *  une image dans les fichiers de l'ordinateur
     */
    private browseImg() {
      (this.$el.querySelector("#imgInput")as any).click();
    }

    private previousSrc = "";
    /**
     * @desc met à jour l'image affichée
     */
    private imgChange() {
      const imgInput = document.getElementById("imgInput") as any;
      if(imgInput.files && imgInput.files[0]) {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(imgInput.files[0]);
        this.imgSrc = imageUrl;
        (this.$refs.imgCropper as ImageCropper).show(this.imgSrc, true, false, 0, '');
      }
    }

    /**
     * @desc affiche l'image contenue dans le dataUrl
     */
    private imageCropped(dataUrl : string) {
      if(dataUrl){
        this.dataUrl = dataUrl;
        this.previousSrc = dataUrl;
        this.setImgPreview();
        (this.imgPreview as CustomImage)?.setSrc(dataUrl);
        this._hasChanged = true;
      }
    }

    /**
     * @desc détruit l'image affichée en preview
     */
    private removeImg() {
      const imgInput = document.getElementById("imgInput") as any;
      imgInput.value = "";
      this.setImgPreview();
      (this.imgPreview as CustomImage)?.setSrc("");
      this.previousSrc = "";
      this._hasChanged = true;
      this.dataUrl = "";
    }


    ////////////////   PUBLIC METHODS    /////////////////////

    /**
     * @desc charge l'image indiquée
     */
    public loadImg(imgId : number) : void {
      this.previousSrc = "";
      this._hasChanged = false;
      this.setImgPreview();
      (this.imgPreview as CustomImage)?.setId(imgId);
    }

    /**
     * @desc renvoie s'il y a eu un changement dans l'image depuis le dernier chargement
     */
    public hasChanged() : boolean {
      return this._hasChanged;
    }

    /**
     * @desc renvoie l'image selectionnée en data Url
     */
    public getDataUrl() : string {
      return this.dataUrl;
    }
  }


