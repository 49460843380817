
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import {Article, ArticleRubricDto} from '@/interfaces/arcticles/Article.entity';

  import 'quill/dist/quill.core.css' // import styles
  import 'quill/dist/quill.snow.css' // for snow theme
  import 'quill/dist/quill.bubble.css' // for bubble theme


  import { quillEditor } from 'vue-quill-editor'
  import RubricSelector from '../RubricSelector.component.vue';
  import ArticleImgSelector from './ArticleImgSelector.component.vue';
  import { AuthenticationService } from '@/services/authentication.service';
  import axios from 'axios';
  import ArticleCoverImgSelector from './ArticleCoverImgSelector.component.vue';
  import { ImageTransferService } from '@/services/image-transfer.service';
  import { ArticleEditionDto } from '@/interfaces/arcticles/ArticleEditionDto.entity';
  import { Utils } from '@/scripts/Utils';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import * as Consts from '@/scripts/Consts';
  import { Rubric } from '@/interfaces/Rubric.interface';
  import DraftSelector from './DraftSelector.component.vue';

  const maxDisplayedChars = 240;
  const textContentFormatSwitchDate = new Date("Thu Aug 18 2022 18:57:22 GMT+0200");

  /**
   * @desc composant qui permet de modifier / créer un article
   */
  @Component({
    components: {
      quillEditor,
      'rubric-selector' : RubricSelector,
      'article-img-selector': ArticleImgSelector,
      'article-cover-img-selector' : ArticleCoverImgSelector,
      'draft-selector' : DraftSelector
    },
  })
  export default class ArticleEditor extends Vue {
    validated = false;
    imageTransferService : ImageTransferService = new ImageTransferService();

    // les options de l'éditeur de texte
    editorOptions = {
      modules: {
        toolbar: [['bold', 'italic', 'underline', 'strike'], 
          [{ 'color': [] }, { 'background': [] }], 
          [{ 'list': 'ordered'}, { 'list': 'bullet' }], 
          
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'align': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['link'],
          ['blockquote'],
        ]
      },
      placeholder: "Rédigez ici",
    }

    // l'article à modifier
    @Prop({type: Article, default: () => {return new Article();}})
    article?: Article;

    @Prop({default: false})
    edition? : boolean;

    /**
     * @desc si une classe édite l'article
     */
    @Prop({default: false})
    classroomEdition? : boolean;

    @Prop({default: false})
    teacherEditArticle? : boolean;

    outputArticle = new Article();
    teacherLoggedIn = false;

    inputError = '';

    initRubrics : Array<number> = [];

    mounted() {
      this.teacherLoggedIn = AuthenticationService.getUserStatus() === Consts.user_status_teacher 
        && !this.edition;
    }

    /**
     * @desc initialise les champs de modification de l'article
     */
    @Watch('article')
    private loadArticle(article: Article) {
      this.article = article;
      this.outputArticle = article.clone();
      this.initRubrics = (article.rubrics as ArticleRubricDto[]).map(x => x.id);
      this.outputArticle.rubrics = article.rubrics.slice();
      this.outputArticle.imgs = article.imgs?.slice();
      const quillEditor = this.$refs.quillEditor as any;
      if(this.article?.createdAt && this.article?.textContent) {
        try {
          if(this.article.createdAt > textContentFormatSwitchDate) { // quill format
            quillEditor.quill.setContents(JSON.parse(this.article.textContent));
          }
          else {
            if(this.article.textContent.substring(0, 2) === '<p') {
              quillEditor.quill.pasteHTML(this.article.textContent);
            }
            else { // quill format
              quillEditor.quill.setContents(JSON.parse(this.article.textContent));
            }
          }
        } catch(err) {
          console.error(err);
        }
      }
      else {
        quillEditor.quill.setContents({});
      }
    }

    /**
     * @desc règle des zones de texte, demandant texte non-vide
     * @param inputValue valeur à vérifier
     */
    private notEmptyRule(inputValue : string) : boolean | string {
      if(!inputValue){
        return "Ne doit pas rester vide";
      }
      return true;
    }

    /**
     * @desc règle de zone de texte, imposant un nombre maximum de caractères
     */
    private maxCountRule(inputValue : string, maxCount : number) {
      if(inputValue.length > maxCount) {
        return 'Trop de caractères entrés';
      }
      return true;
    }

    /**
     * @desc valide l'article créé, et l'envoie au serveur
     */
    private async validate(thenPublish?: boolean) : Promise<void> {
      if(!this.article) return;
      if(this.validated) return;

      if(!this.checkInputData()) return;

      this.validated = true;

      const data = await this.getInputData();
      if(!data) return;

      if((this.edition || (this.teacherLoggedIn && this.article && this.article.id)) && !(data as ArticleEditionDto).changed && !thenPublish) {
        this.$router.push('/article/' + this.article?.id);
        return;
      }
    
      const headers = AuthenticationService.getRequestHeader();
      let url = 
        this.classroomEdition || (this.teacherLoggedIn && !thenPublish) ? UrlConsts.createUnvalidatedArticle : UrlConsts.createArticle;

      if(this.edition || (this.teacherLoggedIn && this.article && this.article.id)) url = UrlConsts.editArticle;

      axios
      .post(url, data, headers)
      .then((response) => {
        if(!this.article) return;
        if(this.edition) {
          if(this.classroomEdition) {
            const query = Utils.createUrlQuery({type:"article-classroom-edition", id: response.data.id});
            this.$router.push('/validation?' + query);
          }
          else if(this.teacherEditArticle) {
            this.$router.push('/gestion/ma-classe');
          }
          else {
            this.$router.push('/article/' + this.article.id);
          }
        }
        else{
          if(this.classroomEdition) {
            const query = Utils.createUrlQuery({type:"article-creation", id: response.data.id});
            this.$router.push('/validation?' + query);
          }
          else if(this.teacherLoggedIn) {
            if(url === UrlConsts.editArticle) {
              if(thenPublish)
                this.publishArticle();
              else
                this.$router.push('/article/' + this.article.id);
            }
            else {
              if(thenPublish) {
                const query = Utils.createUrlQuery({type:"article-validation", id: response.data.id});
                this.$router.push('/validation?' + query);
              }
              else{
                const query = Utils.createUrlQuery({type:"draft-article-creation", id: response.data.id});
                this.$router.push('/validation?' + query);
              }
            }
          }
          else{
            const query = Utils.createUrlQuery({type:"article-validation", id: response.data.id});
            this.$router.push('/validation?' + query);
          }
        }
      })
      .catch(error => {
        this.validated = false;
        console.log(error);
      })
    }


    /**
     * @desc demande la publication de l'article en édition
     */
    private publish() : void {
      if(!this.article) return;
      if(this.validated) return;

      this.validate(true);
    }


    /**
     * @desc envoie une requète server de publication de l'article en cours
     */
    private publishArticle() : void {
      if(!this.article) return;
      if(!this.validated) return;
    
      const headers = AuthenticationService.getRequestHeader();
      let url = UrlConsts.validateArticle;

      axios
      .post(url, {id: this.article.id}, headers)
      .then((response) => {
        const query = Utils.createUrlQuery({type:"article-validation", id: this.article?.id});
        this.$router.push('/validation?' + query);
      })
      .catch(error => {
        console.log(error);
      })
    }

    /**
     * @desc vérifie les données rentrées par l'utilisateur
     * @returns vrai si tout est valide
     */
    private checkInputData() : boolean {
      let valid = true;
      const quillEditor = this.$refs.quillEditor as any;
      const textContent = quillEditor.quill.getText();

      if(!(this.$refs.titleForm as any).validate())
        valid = false;
      if(!textContent) 
        valid = false;
      if((this.$refs.rubricSelector as RubricSelector).hasError())
        valid = false;
      if(!(this.$refs.authorForm as any).validate())
        valid = false;
      if(!(this.$refs.imgSelector as ArticleImgSelector).transferFinished())
        valid = false;

      if(valid) this.inputError = '';
      else this.inputError = 'Vous n\'avez pas rempli correctement une ou plusieurs zones';

      return valid;
    }

    /**
     * @desc retourne les données entrées par l'utilisateur
     */
    private async getInputData() : Promise<Article | ArticleEditionDto | undefined> {
      const result = new Article(this.outputArticle);
      const quillEditor = this.$refs.quillEditor as any;
      result.textContent = JSON.stringify(quillEditor.quill.getContents());
      result.summary = quillEditor.quill.getText().substring(0, maxDisplayedChars);

      const selectedRubrics = (this.$refs.rubricSelector as RubricSelector).getRubricIds();
      result.rubrics = selectedRubrics;

      const selectedImgs = (this.$refs.imgSelector as ArticleImgSelector).getImgs();
      result.imgs = selectedImgs;

      const articleCoverImgSelector = this.$refs.articleCoverImgSelector as ArticleCoverImgSelector;
      if(articleCoverImgSelector.hasChanged()){
        const dataUrl = articleCoverImgSelector.getDataUrl();
        if(dataUrl) {
          try {
            result.coverImgId = await this.serverSaveCoverImg(dataUrl);
          } catch {
            result.coverImgId = -1;
          }
        }
        else {
          // -1 si l'image à simplement été supprimée
          result.coverImgId = -1;
        }
      }

      if((this.edition || (this.teacherLoggedIn && this.article && this.article.id)) && this.article) {
        return result.createArticleEditionDto(this.article);
      }
      else if(!this.edition) return result;
    }

    /**
     * @desc demande la sauvegarde d'une image côté serveur
     * @returns l'id de l'image sauvegardée
     */
    async serverSaveCoverImg(imgUrl : string) : Promise<number> {
      const result = await this.imageTransferService.transferImg(imgUrl, UrlConsts.saveCoverImg);
      if(result.createdImgId) return result.createdImgId;
      return 0;
    }

    /**
     * @desc ouvre la boîte de dialogue avec les brouillons
     */
    private openDraft() : void {
      (this.$refs.draftSelector as DraftSelector).show();
    }

    public loadDraftArticle(article : Article): void {
      this.$emit('loadDraftArticle', article);
    }

    public deletedDraftArticle(article : Article): void {
      this.$emit('deletedDraftArticle', article);
    }
  }


