
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import {ArticleImage} from '@/interfaces/arcticles/ArticleImage.entity';
  import ArticleImageCard from './ArticleImageCard.component.vue';
  import {ImageTransferService} from '@/services/image-transfer.service';
  import axios from 'axios';
  import { AuthenticationService } from '@/services/authentication.service';
  import ImageCropper from '../ImageCropper.component.vue';
  import { ArticleImageDto } from '@/interfaces/arcticles/Article.entity';
  import * as UrlConsts from '@/scripts/UrlConsts';


  const imgWidth = process.env.VUE_APP_IMG_MAX_WIDTH;
  const imgMaxHeight = process.env.VUE_APP_IMG_MAX_HEIGHT;
  const maxFilesTogether = 5;
  const croppedImgEvent = "article-img-cropped";

  /**
   * @desc boite de dialogue pour redimensionner une image selon un cadre indiqué
   */
  @Component({
    components: {
      'article-image-card' : ArticleImageCard,
      'image-cropper' : ImageCropper,
    },
  })
  export default class ArticleImgSelector extends Vue {
    imgs : Array<ArticleImage> = [];
    imgTransferService = new ImageTransferService();

    @Prop({default: false})
    validated? : boolean;

    /**
     * @desc images à afficher à la création du composant
     */
    @Prop({default: []})
    initImgs? : Array<ArticleImageDto>

    @Prop({default: false})
    edition? : boolean;

    @Prop({default: false})
    replaceOnUpdate? : boolean;

    /**
     * @desc ids des images à supprimer en cas de validation
     */
    imgsToDelete : Array<number> = [];

    mounted() : void {
      window.onbeforeunload = function(event : any) {    
        event.returnValue = 'Attention, vous allez perdrer les données enregistrées.';
        return 'Attention, vous allez perdrer les données enregistrées.';
      };
      this.setInitImgs();
    }

    /**
     * @desc fonction appelée avant la destruction du composant
     */
    beforeDestroy() : void {
      this.destroyUnvalidatedData();
      window.onbeforeunload = null;
    }

    /**
     * @desc ajout des images indiquées  à celles selectionnées
     */
    @Watch('initImgs')
    private setInitImgs() : void {
      if(this.initImgs && this.initImgs.length) {
        if(!this.imgs || this.replaceOnUpdate) {
          this.imgs = this.initImgs.map(x => new ArticleImage(x.id, x.order)).sort((a, b) => a.order - b.order);
        }
        else{
          this.imgs = this.imgs.concat(this.initImgs.map(x => new ArticleImage(x.id, x.order)))
                        .sort((a, b) => a.order - b.order);
        }
      }
      else if(this.replaceOnUpdate) {
        this.imgs = [];
      }
    }



    /**
     * @desc demande au serveur la suppression des images chargées
     */
    private destroyUnvalidatedData() {
      if(!this.validated && !this.edition) {
        for(let i=0; i < this.imgs.length; ++i) {
          if(this.imgs[i].id) {
            this.serverDeleteImg(this.imgs[i].id);
          }
        }
      }
    }

    /**
     * @desc met à jour l'ordre des images selectionnées
     */
    private updateOrder(orderData : any) : void {
      if(!orderData || !orderData.id) return;
      const currentIndex = this.imgs.findIndex(x => x.id === orderData.id);
      if(orderData.value > 0 && currentIndex < this.imgs.length-1) {
        this.imgs.splice(currentIndex, 2, this.imgs[currentIndex+1], this.imgs[currentIndex]);
      }
      if(orderData.value < 0 && currentIndex > 0) {
        this.imgs.splice(currentIndex-1, 2, this.imgs[currentIndex], this.imgs[currentIndex-1]);
      }
    }

    /**
     * @desc appel la boite de dialogue de selection des images du file-input
     */
    private browseImages() : void {
      const fileInput = this.$el.querySelector("#file-input") as HTMLElement;
      if(fileInput) {
        fileInput.click();
      }
    }

    // fichiers à envoyer
    filesToSend : Array<any> = [];
    // nombre de fichiers en train d'être envoyés
    sendingFiles = 0;

    /**
     * @desc récupération et chargement des images selectionnées par le file input
     */
    async imgInput() : Promise<void> {
      const fileInput = this.$el.querySelector("#file-input") as HTMLInputElement;
      if(!fileInput) return; 

      const files = fileInput.files;
      if(files && files.length) {
        let urlCreator = window.URL || window.webkitURL;
        for(let i=0; i < files.length; ++i) {
          const compressedImg = await this.imgTransferService.resizeImg(files[i], imgWidth, imgMaxHeight);
          const base64Url = await this.toBase64Data(compressedImg);
          const imageUrl = urlCreator.createObjectURL(compressedImg);
          const createdImage = new ArticleImage(0, this.imgs.length+1, imageUrl, 1);
          this.imgs.push(createdImage);

          if(this.sendingFiles < maxFilesTogether) {
            createdImage.updateOnIdUpdate = false;
            this.serverSaveImg(base64Url, createdImage).then(() => {
              createdImage.updateOnIdUpdate = true;
            }).catch(() => {
              const createdImgIndex = this.imgs.indexOf(createdImage);
              if(createdImgIndex > -1) {
                this.imgs.splice(createdImgIndex, 1);
              }
            });
            ++this.sendingFiles;
          }
          else {
            this.filesToSend.push({base64Url, createdImage});
          }
        }
      }
    }

    /**
     * @desc converti un fichier en base64
     */
    private toBase64Data(blobFile : File) : Promise<string> {
      return new Promise<any>((resolve) => {
        let reader = new FileReader();
        reader.onloadend = function() {
          resolve(reader.result);
        }
        reader.readAsDataURL(blobFile);
      })
    }

    /**
     * @desc demande la sauvegarde d'une image côté serveur
     * @returns l'id de l'image sauvegardée
     */
    async serverSaveImg(imgUrl : string, articleImage : ArticleImage) : Promise<number> {
      // token permettant d'interrrompre la transmission du fichier
      const cancelTokenSource = axios.CancelToken.source();
      articleImage.cancelToken = cancelTokenSource;
      const result = await this.imgTransferService.transferArticleImg(imgUrl, articleImage.order, 0,
        (percents : number, data : ArticleImage) => {
          data.loading = percents;
        },
        articleImage, articleImage.cancelToken);

      if(result.createdImgId) {
        articleImage.loading = -1;
        articleImage.id = result.createdImgId;
        articleImage.rotation = 0;
        this.imgSaved(result.createdImgId);
        return result.createdImgId;
      }
      return 0;
    }

    /**
     * @desc met à jour l'envoi des fichier à envoyer
     */
    imgSaved(createdId : number) : void{
      --this.sendingFiles;
      if(this.sendingFiles < 0) this.sendingFiles = 0;

      const filesToSendCount = maxFilesTogether-this.sendingFiles;
      for(let i=0; i < filesToSendCount; ++i) {
        if(this.filesToSend.length > 0) {
          if(this.imgs.includes(this.filesToSend[0].createdImage)){
            ++this.sendingFiles;
            this.filesToSend[0].createdImage.updateOnIdUpdate = false;
            this.serverSaveImg(this.filesToSend[0].base64Url, this.filesToSend[0].createdImage).then(() => {
              this.filesToSend[0].createdImage.updateOnIdUpdate = true;
            }).catch(() => {
              const createdImgIndex = this.imgs.indexOf(this.filesToSend[0].createdImage);
              if(createdImgIndex > -1) {
                this.imgs.splice(createdImgIndex, 1);
              }
            });
          }
          this.filesToSend.splice(0, 1);
        }
      }
    }

    /**
     * @desc suppression de l'image, soit arrêt de l'envoi
     *  soit requète serveur pour supprimer l'image
     */
    private deleteCard(deletionData: any) : void {
      if(!deletionData?.order) return;
      const order = deletionData.order;
      const imgIndex = this.imgs.findIndex(x => x.order === order);
      if(imgIndex > -1) {
        const img = this.imgs[imgIndex];
        if(img.loading !== -1 && img.loading !== 100) {
          if(img.cancelToken) {
            img.cancelToken.cancel();
          }
          else{
            const fileToSendIndex = this.filesToSend.findIndex(x => x.createdImage === img);
            if(fileToSendIndex > -1){
              this.filesToSend.splice(fileToSendIndex, 1);
            }
          }
          this.imgs.splice(imgIndex, 1);
        }
        else if(img.id) {
          if(this.edition) {
            this.imgsToDelete.push(img.id);
            this.imgs.splice(imgIndex, 1);
          }
          else{
            this.serverDeleteImg(img.id).then(() => {
              this.imgs.splice(imgIndex, 1);
            });
          }
        }
      }
    }

    /**
     * @desc envoie d'une requète serveur pour supprimer l'image
     */
    private serverDeleteImg(imgId: number) : Promise<any>{
      return new Promise<any>((resolve, reject) => {
        const headers = AuthenticationService.getRequestHeader();
        axios
        .delete(  UrlConsts.deleteArticleImg + '/' + imgId, headers)
        .then((response) => {
          resolve(imgId);
        })
        .catch(error => {
          reject();
          console.log(error);
        })
      })
    }

    /**
     * @desc tourne l'image indiquée
     * @param id id de l'image à tourner
     */
    private rotateImg(rotationData: any): void {
      if(!rotationData?.angle || !rotationData?.id) return;
      const {id, angle} = rotationData;
      const imgIndex = this.imgs.findIndex(x => x.id === id);
      if(imgIndex > -1) {
        this.imgs[imgIndex].rotation = ((this.imgs[imgIndex].rotation || 0) + angle) % 360;
      }
    }

    /**
     * @desc ouvre l'ImageCropper, la boite de dialogue de modification d'une image
     */
    private editImg(editionData: any) {
      if(!editionData?.id) return;
      const id = editionData?.id;
      const imgIndex = this.imgs.findIndex(x => x.id === id);
      if(imgIndex <= -1) return;

      const imgCards = this.$refs.imgCard as ArticleImageCard[];
      if(!imgCards || !imgCards.length) return;

      const card = imgCards.find(x => x.imgId === id);
      if(!card) return;

      const src = card?.getImgSrc();

      (this.$refs.imgCropper as ImageCropper).show(src, false, false, this.imgs[imgIndex].rotation, this.imgs[imgIndex]);
    }

    /**
     * @desc met à jour l'image modifiée
     * @param dataUrl l'image modifiée en base64
     */
    private async updateEditedImg(dataUrl : string, articleImg : ArticleImage) : Promise<void> {
      if(!dataUrl || !articleImg) return;
      const imgIndex = this.imgs.findIndex(x => x.id === articleImg.id);
      if(imgIndex <= -1) return;

      
      this.serverSaveImg(dataUrl, articleImg)
        .then(() => {
          if(this.edition) {
            this.imgsToDelete.push(articleImg.id);
          }
          else{
            this.serverDeleteImg(articleImg.id);
          }
        });
    }

  ///////////////// //////    PUBLIC     //////////////////////

    /**
     * @desc fixe les images à charger / modifier
     */
    public setImages(imgs : Array<ArticleImage>) : void {
      this.imgs = imgs;
    }

    /**
     * @desc vérifie si toutes les images à transférer on fini leur transfert
     * @returns vrai si toutes les images ont été transférées
     */
    public transferFinished() : boolean {
      if(this.imgs.find(x => x.loading !== -1)) return false;
      return true;
    }

    /**
     * @desc renvoie toutes les images selectionnées
     */
    public getImgs() : Array<ArticleImageDto>{
      const currentImgs = this.imgs.map((x: ArticleImage, index : number) : ArticleImageDto => {
        return new ArticleImageDto({id: x.id, order: index +1, rotation: x.rotation});
      });

      return currentImgs.concat(this.imgsToDelete.map(x => new ArticleImageDto({id: x, deletion: true})));
    }
  }
