
  import { Article } from '@/interfaces/arcticles/Article.entity';
  import { AuthenticationService } from '@/services/authentication.service';
  import axios from 'axios';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import DialogBoxValidation from '../DialogBoxValidation.vue';
  import { Utils } from '@/scripts/Utils';


  /**
   * @desc boite de dialogue pour selectionner un brouillon à éditer
   */
  @Component({
    components: {
      'dialog-box-validation' : DialogBoxValidation
    },
  })
  export default class DraftSelector extends Vue {
    boxWidth = "55rem";
    visible = false;
    deletionEventName = 'deleted';

    draftArticles : Article[] = [];

    mounted() : void {
      this.loadDraftArticles();
    }

    /**
     * @desc montre la boite de dialogue
     */
    show() : void {
      this.visible= false;
      setTimeout(() => {
         this.visible= true;
        this.$vuetify.theme.dark = false;
      }, 1);
    } 

    /**
     * @desc charge les articles non-validés(brouillons)
     */
    loadDraftArticles() : void {
      const headers = AuthenticationService.getRequestHeader();

      axios
        .get(UrlConsts.getAllDraftArticles, headers)
        .then((response) => {
          this.draftArticles = response.data.map((x : any) => new Article(x));
        })
        .catch(error => console.log(error))
    }

    /**
     * @desc envoie un événement demandant l'édition de l'article selectionné
     */
    edit(article : Article) : void {
      setTimeout(() => {
        this.$emit('loadDraftArticle', article);
        this.visible = false;
      }, 200);
    }

    /**
     * @desc ouvre la boite de dialogue demandant la confirmation de la suppression
     */
    deleteDraft(article : Article) : void {
      (this.$refs.deletionDialogBox as DialogBoxValidation).show(article);
    }


    deletion = false;
    /**
     * @desc envoie la requète server de suppression de l'article,
     */
    private serverDeleteArticle(article : Article) : void {
      if(!article || this.deletion) return;
      const headers = AuthenticationService.getRequestHeader();
      
      this.deletion = true;
      axios
        .delete(  UrlConsts.deleteArticle + '/' + article.id, headers)
        .then(() => {
          this.deletion = false;
          this.$emit('deletedDraftArticle', article);
          const index = this.draftArticles.findIndex(x => x.id === article.id);
          if(index > -1) {
            this.draftArticles.splice(index, 1);
          }
        })
        .catch(error => {
          this.deletion = false;
          console.log(error);
        })
    }

    /**
     * @desc envoie un événement demandant l'édition d'un nouvel article
     */
    newArticle() {
      setTimeout(() => {
        this.$emit('loadDraftArticle', new Article());
        this.visible = false;
      }, 200);
    }

    /**
     * @desc fonction appelée en cas d'annulation. cache simplement la boite de dialogue
     */
    cancel() : void {
      setTimeout(() => {
        this.visible = false;
      }, 200);
    }
  }


