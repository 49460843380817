
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import axios from 'axios';
  import { AuthenticationService } from '../services/authentication.service';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import CheckboxList from './CheckboxList.component.vue';
  import { Rubric } from '@/interfaces/Rubric.interface';
  import Container from './Container.component.vue';
import { Utils } from '@/scripts/Utils';


  const rubricType = 1;
  const classType = 2;


    // note: possibilité de selectionner des rubriques archivées

  @Component({
    components: {
      'checkbox-list' : CheckboxList,
      'container' : Container,
    },
  })
  export default class RubricSelector extends Vue {
    @Prop({default: []})
    initSelectedRubrics? : Array<number>;

    selectableRubrics : Array<{id: number, label: string}> = [];
    selectableClasses : Array<{id: number, label: string}> = [];

    selectableArchivedRubrics : Array<{id: number, label: string}> = [];
    selectableArchivedClasses : Array<{id: number, label: string}> = [];

    selectedProjects : Array<number> = [];
    selectedClasses : Array<number> = [];
    selectedArchivedProjects : Array<number> = [];
    selectedArchivedClasses : Array<number> = [];

    initSelectedProjects : Array<number> = [];
    initSelectedClasses : Array<number> = [];
    initSelectedArchivedProjects : Array<number> = [];
    initSelectedArchivedClasses : Array<number> = [];

    rubricCountCss = "--rubricCount: 0";

    mounted() : void {
      this.loadRubrics();
    }

    private updateSelectedRubrics(selectionArray: number[], selected: number[]): void {
        selectionArray.splice(0, selectionArray.length, ...selected);
        this.checkboxRules();
    }

    /**
     * @desc met à jour les rubriques selectionnées
     */
    @Watch('initSelectedRubrics') 
    private setSelectedRubrics() : void {
      if(this.initSelectedRubrics) {
        if(this.selectableRubrics.length) {
          this.selectedProjects =
            this.selectableRubrics.filter(x => this.initSelectedRubrics?.includes(x.id))
            .map(x => x.id);
        }
        if(this.selectableClasses.length) {
          this.selectedClasses = 
            this.selectableClasses.filter(x => this.initSelectedRubrics?.includes(x.id))
            .map(x => x.id);
        }
        if(this.selectableArchivedRubrics.length) {
          this.selectedArchivedProjects =
            this.selectableArchivedRubrics.filter(x => this.initSelectedRubrics?.includes(x.id))
            .map(x => x.id);
        }
        if(this.selectableArchivedClasses.length) {
          this.selectedArchivedClasses = 
            this.selectableArchivedClasses.filter(x => this.initSelectedRubrics?.includes(x.id))
            .map(x => x.id);
        }
      }
      else {
        this.selectedProjects = [];
        this.selectedClasses = [];
        this.selectedArchivedProjects = [];
        this.selectedArchivedClasses = [];
      }

      this.initSelectedProjects = this.selectedProjects.slice();
      this.initSelectedClasses = this.selectedClasses.slice();
      this.initSelectedArchivedProjects = this.selectedArchivedProjects.slice();
      this.initSelectedArchivedClasses = this.selectedArchivedClasses.slice();
    }

    /**
     * @desc règle appliquée aux checkboxes : au moins une doit être cochée
     */
    private checkboxRules() : void{
      if(!this.selectedProjects.length && !this.selectedClasses.length 
        && !this.selectedArchivedProjects.length && !this.selectedArchivedClasses.length) {
        (this.$refs.rubricList as CheckboxList)?.setErrors(["Vous devez renseigner au moins une rubrique"]);
        (this.$refs.classList as CheckboxList)?.setErrors(["Vous devez renseigner au moins une rubrique"]);
        (this.$refs.archivedcRubricsList as CheckboxList)?.setErrors(["Vous devez renseigner au moins une rubrique"]);
        (this.$refs.archivedcClassesList as CheckboxList)?.setErrors(["Vous devez renseigner au moins une rubrique"]);
      }
      else{
        (this.$refs.rubricList as CheckboxList)?.setErrors([]);
        (this.$refs.classList as CheckboxList)?.setErrors([]);
        (this.$refs.archivedcRubricsList as CheckboxList)?.setErrors([]);
        (this.$refs.archivedcClassesList as CheckboxList)?.setErrors([]);
      }
    }

    /**
     * @desc charge les rubrique pour pouvoir les proposer à la selection
     */
    private loadRubrics() : void {
      const headers = AuthenticationService.getRequestHeader();

      axios
        .get(UrlConsts.getAllRubrics, headers)
        .then((response) => {
          const rubrics = response.data as Rubric[];
          for(let i=0; i < rubrics.length; ++i) {
            if(rubrics[i].type === rubricType) {
              if(new Date(rubrics[i].archivedDate) < new Date()) { // archivée
                this.selectableArchivedRubrics.push({id: rubrics[i].id, label: this.rubricFullName(rubrics[i])});
              }
              else {
                this.selectableRubrics.push({id: rubrics[i].id, label: this.rubricFullName(rubrics[i])});
              }
            }
            else if(rubrics[i].type === classType) {
              if(new Date(rubrics[i].archivedDate) < new Date()) { // archivée
                this.selectableArchivedClasses.push({id: rubrics[i].id, label: this.rubricFullName(rubrics[i])});
              }
              else {
                this.selectableClasses.push({id: rubrics[i].id, label: this.rubricFullName(rubrics[i])});
              }
            }
          }
          this.setSelectedRubrics();
        })
        .catch(error => console.log(error))
    }

    /**
     * @desc modifie la visibilité des rubriques archivées
     */
    private switchArchivedRubricsVisibility() {
      this.rubricCountCss = "--rubricCount: " + Math.max(this.selectableArchivedRubrics.length, this.selectableArchivedClasses.length);
      const container = (this.$refs.archivedSectionsBox as Container).$el;
      const archivedSections = (this.$refs.archivedSections as Container).$el;
      const currentValue = container?.getAttribute("hide");
      if(currentValue === "true"){
        container?.setAttribute("hide", "false");
      }
      else{
        container?.setAttribute("hide", "true");
      }
    }

    private rubricFullName(rubric : Rubric) {
      return Utils.getRubricFullName(rubric.startDate, rubric.endDate, rubric.name);
    }


    /**
     *  @desc vérifie s'il y a des erreurs => si au moins une checkbox est selectionée
     */
    public hasError() : boolean {
      this.checkboxRules();
      return ((this.$refs.rubricList as CheckboxList).hasError() || (this.$refs.classList as CheckboxList).hasError());
    }

    /**
     * @desc retourne les id des rubriques selectionnées 
     */
    public getRubricIds() : Array<number> {
      return this.selectedProjects.concat(this.selectedClasses).concat(this.selectedArchivedProjects).concat(this.selectedArchivedClasses);
    }
  }
