
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import {Article} from '@/interfaces/arcticles/Article.entity';
  import {ArticleImage} from '@/interfaces/arcticles/ArticleImage.entity';
  import CustomImage from '../CustomImage.component.vue';

  import KProgress from 'k-progress';

  /**
   * @desc boite de dialogue pour redimensionner une image selon un cadre indiqué
   */
  @Component({
    components: {
      'c-img' : CustomImage,
      'k-progress' : KProgress
    },
  })
  export default class ArticleImageCard extends Vue {
    @Prop({default: 0})
    imgId? : number;

    @Prop({default: 0})
    order? : number;

    @Prop()
    src? : string;

    @Prop()
    loading? : number;

    @Prop({default: 0})
    rotation? : number;

    @Prop({default: true})
    updateOnIdUpdate? : boolean;

    /**
     * @desc initialise l'affichage de l'image
     */
    mounted() {
      if(this.imgId)
        (this.$refs.imgPreview as CustomImage).setId(this.imgId as number);
      if(this.src)
        (this.$refs.imgPreview as CustomImage).setSrc(this.src);
    }

    /**
     * @desc met à jour l'affichage de l'image
     */
    @Watch('imgId')
    private imgIdChanged() {
      if(this.imgId && this.updateOnIdUpdate)
        (this.$refs.imgPreview as CustomImage).setId(this.imgId as number);
    }

    /**
     * @desc met à jour l'affichage de l'image
     */
    @Watch('src')
    private srcChanged() {
      if(this.src)
        (this.$refs.imgPreview as CustomImage).setSrc(this.src);
    }

    /**
     * @desc tourne la boite contenant l'image à afficher
     */
    @Watch('rotation')
    private rotationChanged() {
      (this.$el.querySelector(".img-preview-box") as any).setAttribute('rotation', this.getRotationName(this.rotation));
    }

    /**
     * @desc associe un nom de classe à un angle donné
     */
    private getRotationName(angle : number | undefined) : string {
      switch(angle) {
        case 90:
          return 'L';
        case -90:
          return 'R';

        case 180:
          return 'Re';
        case -180:
          return 'Re';

        case 270:
          return 'R';
        case -270:
          return 'L';
      }
      return '';
    }

    /**
     * @desc demande la rotation de l'image actuelle aux parents
     */
    private rotate(angle : number) : void {
      this.$emit("rotate", {id: this.imgId, angle});
    }

    /**
     * @desc demande le changement d'ordre de l'image au parent
     */
    private move(value : number) {
      this.$emit("orderChanged", {id: this.imgId, value});
    }

    /**
     * @desc demande l'édition de l'image indiquée
     */
    private imgEdition() {
      this.$emit("editImg", {id: this.imgId});
    }

    /**
     * @desc demande la suppression de la carte et de l'image contenue
     */
    private deleteCard() : void {
      this.$emit("deleteCard", {order: this.order});
    }

    /**
     * @desc demande la src de l'image affichée
     * utilisée pour ne pas avoir à redemander plusieurs fois l'image au serveur
     */
    public getImgSrc() {
      return (this.$refs.imgPreview as CustomImage).getSrc();
    }
  }
