/**
 * @desc image d'article
 */
export class ArticleImage {

  constructor(id? : number, order? : number, src? : string, loading?: number, rotation? : number) {
    this.id = id || 0;
    this.order = order || 0;
    this.rotation = rotation || 0;
    if(src) this.src = src;
    this.loading = loading || -1;
  }

  id: number;
  order: number;
  src? : string;
  loading? : number;
  cancelToken? : any;
  rotation? : number;
  updateOnIdUpdate = true;

  public clone(): any {
    const cloneObj = new ArticleImage() as any;
    for (const attribut in this) {
      cloneObj[attribut] = this[attribut];
    }
    return cloneObj;
  }
}