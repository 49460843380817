
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

  /**
   * @desc composant permettant de selectionner des éléments parmis une liste via des cases à cocher
   */
  @Component({
    components: {
    },
  })
  export default class CheckboxList extends Vue {
    @Prop({default: []})
    initSelectedCheckboxes? : Array<number>;

    @Prop({default: "boxChecked"})
    checkEvent?: string;

    @Prop({default: []})
    selectableCheckboxes? : Array<{id: number, label: string}>;

    selectedCheckboxes : Array<number> = [];

    errors : string[] = [];

    mounted() : void {
      this.setSelectedCheckboxes();
    }

    /**
     * @desc demande l'initialisation des cases à cocher
     */
    @Watch('selectableCheckboxes') 
    private setSelectableCheckboxes() : void {
      this.setSelectedCheckboxes();
    }

    /**
     * @desc initialise les cases à cocher avec les données envoyées
     */
    @Watch('initSelectedCheckboxes') 
    private setSelectedCheckboxes() : void {
      if(this.initSelectedCheckboxes) {
        if(this.selectableCheckboxes?.length) {
          this.selectedCheckboxes =
            this.selectableCheckboxes.filter(x => this.initSelectedCheckboxes?.includes(x.id))
            .map(x => x.id);
        }
      }
      else {
        this.selectedCheckboxes = [];
      }
    }

    /**
     * @desc règle appliquée aux checkboxes : au moins une doit être cochée
     */
    private checkboxRules() : boolean | string {
      this.$emit("boxChecked", this.selectedCheckboxes);
      return true;
    }

    /**
     * @desc défini les erreur du composant
     */
    public setErrors(errors: string[]) : void {
      this.errors = errors;
    }

    /**
     * @desc renvoie vrai si le composant a au moins une erreur
     */
    public hasError() : boolean {
      return this.errors.length > 0;
    }
  }
